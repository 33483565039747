<template>
  <main id="Basic">
    <CContainer>
      <CCard>
        <CCardHeader>
          <h5 class="mb-0">{{ $t('Navigation.Channel/Subscription') }}</h5>
        </CCardHeader>
        <CCardBody>
          <CTabs variant="pills" :vertical="{ navs: 'col-lg-2 col-md-3 col-sm-12 col-12 mb-4', content: 'col-lg-10 col-md-9 col-sm-12 col-12' }">
            <CTab :title="$t('Channel/Subscription.Index')">
              <h5 class="d-flex justify-content-between align-items-center">
                  {{ $t('Channel/Subscription.Index') }}
                  <WidgetsLocales v-if="$store.state.user.permission.MutipleLocale" :NowChooseLanguage.sync="NowChooseLanguage"></WidgetsLocales>
              </h5>
              <hr>
              <template v-if="NowChooseLanguage === 'default'">
                <Draggable
                  v-model="BasicData.SubscriptionPlatform.Index"
                  class="list-group"
                  tag="ul"
                  v-bind="DragOptions"
                  @start="drag = true"
                  @end="drag = false"
                >
                  <CCard v-for="(item, index) in BasicData.SubscriptionPlatform.Index" :key="index">
                    <CCardHeader class="d-flex align-items-center">
                      <div class="flex-grow-1" style="cursor: pointer;" @click="(MenuAccordion = MenuAccordion === index ? false : index)">
                        {{ item.Name }}
                      </div>
                      <CButton size="sm" color="danger" @click="DeleteIndexSection(`${index}`)">{{ $t('Global.Delete') }}</CButton>
                    </CCardHeader>
                    <CCollapse :show="MenuAccordion === index">
                      <CCardBody>
                        <CInput v-model="item.Name" :label="$t('Channel/Subscription.SectionName')"/>
                        <CSelect :label="$t('Product/Detail.Categories')" :options="CategoryList" v-model="item.Category" :value.sync="item.Category" :placeholder="$t('Global.PleaseSelect')"/>
                        <CSelect :label="$t('Channel/Subscription.Mode')" :options="ModeOptions" v-model="item.Mode" :value.sync="item.Mode" />
                      </CCardBody>
                    </CCollapse>
                  </CCard>
                </Draggable>
              </template>
              <template v-else>
                <Draggable
                  v-model="BasicData.SubscriptionPlatform['Index_' + NowChooseLanguage]"
                  class="list-group"
                  tag="ul"
                  v-bind="DragOptions"
                  @start="drag = true"
                  @end="drag = false"
                >
                  <CCard v-for="(item, index) in BasicData.SubscriptionPlatform['Index_' + NowChooseLanguage]" :key="index">
                    <CCardHeader class="d-flex align-items-center">
                      <div class="flex-grow-1" style="cursor: pointer;" @click="(MenuAccordion = MenuAccordion === index ? false : index)">
                        {{ item.Name }}
                      </div>
                      <CButton size="sm" color="danger" @click="DeleteIndexSection(`${index}`)">{{ $t('Global.Delete') }}</CButton>
                    </CCardHeader>
                    <CCollapse :show="MenuAccordion === index">
                      <CCardBody>
                        <CInput v-model="item.Name" :label="$t('Channel/Subscription.SectionName')"/>
                        <CSelect :label="$t('Product/Detail.Categories')" :options="CategoryList" v-model="item.Category" :value.sync="item.Category" :placeholder="$t('Global.PleaseSelect')"/>
                        <CSelect :label="$t('Channel/Subscription.Mode')" :options="ModeOptions" v-model="item.Mode" :value.sync="item.Mode" />
                      </CCardBody>
                    </CCollapse>
                  </CCard>
                </Draggable>
              </template>
              <hr>
              <div class="d-flex justify-content-between">
                <CButton color="info" class="px-4 mt-3 mr-1" @click="AddIndexSection">{{ $t('Global.Add') }}</CButton>
                <CButton color="success" class="px-4 mt-3" @click="SetData('SubscriptionPlatform')">{{ $t('Global.Update') }}</CButton>
              </div>
            </CTab>
            <CTab :title="$t('System/Themes.PopUp')">
              <h5 class="d-flex justify-content-between">
                {{ $t('System/Themes.PopUp') }}
              </h5>
              <hr>
              <CCard>
                <CCardHeader class="d-flex align-items-center">
                  <div class="flex-grow-1" style="cursor: pointer;" @click="(PopAccordion = PopAccordion === 'Default' ? false : 'Default')">
                    {{ $t('Locale.Default') }}
                  </div>
                </CCardHeader>
                <CCollapse :show="PopAccordion === 'Default'">
                  <CCardBody v-if="BasicData.SubscriptionPlatform.PopUp">
                    <div class="w-100 d-flex flex-column my-2 mb-4">
                      <label>啟用彈出視窗</label>
                      <CSwitch color="success" :checked.sync="BasicData.SubscriptionPlatform.PopUp.Enable" />
                      <label class="mt-3">圖片</label>
                      <CRow>
                        <CCol lg="4" md="6">
                          <div v-if="BasicData.SubscriptionPlatform.PopUp.ImageURL" class="position-relative CarouselItem">
                            <img v-lazy="BasicData.SubscriptionPlatform.PopUp.ImageURL" class="img-fluid" />
                            <div class="CarouselTools">
                              <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('PopUp', 'ImageURL')">
                                <CIcon name="cil-pencil" />
                              </CButton>
                            </div>
                          </div>
                          <div v-else class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                            <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                            <div class="CarouselTools">
                              <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('PopUp', 'ImageURL')">
                                <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                              </CButton>
                            </div>
                          </div>
                        </CCol>
                      </CRow>
                    </div>
                  </CCardBody>
                  <CCardFooter>
                    <CButton color="success" @click="SetData('SubscriptionPlatform')">
                      {{ $t('Global.Update') }}
                    </CButton>
                  </CCardFooter>
                </CCollapse>
              </CCard>
              <template v-if="$store.state.user.permission.MutipleLocale">
                <CCard v-for="(locale, index) in $store.state.user.permission.Locales" :key="'PopUp_' + locale">
                  <CCardHeader class="d-flex align-items-center">
                    <div class="flex-grow-1" style="cursor: pointer;" @click="(PopAccordion = PopAccordion === index ? false : index)">
                      {{ $t('Locale.' + locale) }}
                    </div>
                  </CCardHeader>
                  <CCollapse :show="PopAccordion === index">
                    <CCardBody v-if="BasicData.SubscriptionPlatform['PopUp_' + locale]">
                      <div class="w-100 d-flex flex-column my-2 mb-4">
                        <label>啟用彈出視窗</label>
                        <CSwitch color="success" :checked.sync="BasicData.SubscriptionPlatform['PopUp_' + locale].Enable" />
                        <label class="mt-3">圖片</label>
                        <CRow>
                          <CCol lg="4" md="6">
                            <div v-if="BasicData.SubscriptionPlatform['PopUp_' + locale].ImageURL" class="position-relative CarouselItem">
                              <img v-lazy="BasicData.SubscriptionPlatform['PopUp_' + locale].ImageURL" class="img-fluid" />
                              <div class="CarouselTools">
                                <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('PopUp_' + locale, 'ImageURL')">
                                  <CIcon name="cil-pencil" />
                                </CButton>
                              </div>
                            </div>
                            <div v-else class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                              <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                              <div class="CarouselTools">
                                <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('PopUp_' + locale, 'ImageURL')">
                                  <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                                </CButton>
                              </div>
                            </div>
                          </CCol>
                        </CRow>
                      </div>
                    </CCardBody>
                    <CCardFooter>
                      <CButton color="success" @click="SetData('SubscriptionPlatform')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardFooter>
                  </CCollapse>
                </CCard>
              </template>
            </CTab>
            <CTab :title="$t('System/Themes.Menu.Title')">
              <h5 class="d-flex justify-content-between">
                {{ $t('System/Themes.Menu.Title') }}
              </h5>
              <hr>

              <CCard>
                <CCardHeader class="d-flex align-items-center">
                  <div class="flex-grow-1" style="cursor: pointer;" @click="(PopAccordion = PopAccordion === 'Default' ? false : 'Default')">
                    {{ $t('Locale.Default') }}
                  </div>
                </CCardHeader>
                <CCollapse :show="PopAccordion === 'Default'">
                  <CCardBody v-if="BasicData.SubscriptionPlatform.Menu">
                    <Draggable
                      v-model="BasicData.SubscriptionPlatform.Menu"
                      class="list-group"
                      tag="ul"
                      v-bind="DragOptions"
                      @start="drag = true"
                      @end="drag = false"
                    >
                      <CCard v-for="(item, index) in BasicData.SubscriptionPlatform.Menu" :key="index">
                        <CCardHeader class="d-flex align-items-center">
                          <div class="flex-grow-1" style="cursor: pointer;" @click="(MenuAccordion = MenuAccordion === index ? false : index)">
                            {{ item.Name }}
                          </div>
                          <CButton size="sm" color="danger" @click="DeleteMenuItem(`${index}`)">{{ $t('Global.Delete') }}</CButton>
                        </CCardHeader>
                        <CCollapse :show="MenuAccordion === index">
                          <CCardBody class="d-flex">
                            <CRow class="w-100">
                              <CCol>
                                <CInput v-model="item.Name" :label="$t('System/Themes.Menu.Name')"/>
                                <CInput v-model="item.SubTitle" :label="$t('System/Themes.Menu.SubTitle')"/>
                                <CInput v-model="item.URL" :label="$t('System/Themes.Menu.URL')"/>
                                <CSelect :value.sync="item.Target" :label="$t('System/Themes.Menu.Target')" :options="[{ label: $t('System/Themes.Menu.Target/Self'), value: '_self' }, { label: $t('System/Themes.Menu.Target/Blank'), value: '_blank' }]"/>
                                <div class="w-100 d-flex flex-column my-2 mb-4">
                                  <label for="MenuSwitch">啟用選單</label>
                                  <CSwitch
                                    color="success"
                                    shape="pill"
                                    :checked.sync="item.Open"
                                    id="MenuSwitch"
                                  />
                                </div>
                                <CButton block color="info" @click="AddSubMenuItem(index)">{{ $t('Global.Add') }}{{ $t('System/Themes.Menu.SubMenu') }}</CButton>
                              </CCol>
                              <CCol>
                                <h5>{{ $t('System/Themes.Menu.SubMenu') }}</h5>
                                <Draggable
                                  v-model="item.SubMenu"
                                  v-bind="DragOptions"
                                  @start="drag = true"
                                  @end="drag = false"
                                >
                                  <CCard v-for="(subItem, subIndex) in item.SubMenu" :key="subIndex">
                                    <CCardHeader class="d-flex align-items-center">
                                      <div class="flex-grow-1" style="cursor: pointer;" @click="(SubMenuAccordion = SubMenuAccordion === `${index}-${subIndex}` ? false : `${index}-${subIndex}`)">
                                        {{ subItem.Name }}
                                      </div>
                                      <CButton color="danger" size="sm" @click="DeleteMenuItem(`${index}-${subIndex}`)">{{ $t('Global.Delete') }}</CButton>
                                    </CCardHeader>
                                    <CCollapse :show="SubMenuAccordion === `${index}-${subIndex}`">
                                      <CCardBody class="d-flex flex-column">
                                        <CInput v-model="subItem.Name" :label="$t('System/Themes.Menu.Name')"/>
                                        <CInput v-model="subItem.URL" :label="$t('System/Themes.Menu.URL')"/>
                                        <CSelect :value.sync="subItem.Target" :label="$t('System/Themes.Menu.Target')" :options="[{ label: $t('System/Themes.Menu.Target/Self'), value: '_self' }, { label: $t('System/Themes.Menu.Target/Blank'), value: '_blank' }]"/>
                                        <label for="MenuSwitch">啟用選單</label>
                                        <CSwitch
                                          color="success"
                                          shape="pill"
                                          :checked.sync="subItem.Open"
                                          id="MenuSwitch"
                                        />
                                      </CCardBody>
                                    </CCollapse>
                                  </CCard>
                                </Draggable>
                              </CCol>
                            </CRow>
                          </CCardBody>
                        </CCollapse>
                      </CCard>
                    </Draggable>
                  </CCardBody>
                  <CCardFooter class="d-flex justify-content-between">
                    <CButton color="info" class="px-4 mt-3 mr-1" @click="AddMenuItem('default')">{{ $t('Global.Add') }}</CButton>
                    <CButton color="success" class="px-4 mt-3" @click="SetData('SubscriptionPlatform')">
                      {{ $t('Global.Update') }}
                    </CButton>
                  </CCardFooter>
                </CCollapse>
              </CCard>
              <template v-if="$store.state.user.permission.MutipleLocale">
                <CCard v-for="(locale, index) in $store.state.user.permission.Locales" :key="'Menu_' + locale">
                  <CCardHeader class="d-flex align-items-center">
                    <div class="flex-grow-1" style="cursor: pointer;" @click="(PopAccordion = PopAccordion === index ? false : index)">
                      {{ $t('Locale.' + locale) }}
                    </div>
                  </CCardHeader>
                  <CCollapse :show="PopAccordion === index">
                    <CCardBody v-if="BasicData.SubscriptionPlatform['Menu_' + locale]">
                      <Draggable
                        v-model="BasicData.SubscriptionPlatform['Menu_' + locale]"
                        class="list-group"
                        tag="ul"
                        v-bind="DragOptions"
                        @start="drag = true"
                        @end="drag = false"
                      >
                        <CCard v-for="(item, index) in BasicData.SubscriptionPlatform['Menu_' + locale]" :key="index">
                          <CCardHeader class="d-flex align-items-center">
                            <div class="flex-grow-1" style="cursor: pointer;" @click="(MenuAccordion = MenuAccordion === index ? false : index)">
                              {{ item.Name }}
                            </div>
                            <CButton size="sm" color="danger" @click="DeleteLocaleMenuItem(`${index}`, locale)">{{ $t('Global.Delete') }}</CButton>
                          </CCardHeader>
                          <CCollapse :show="MenuAccordion === index">
                            <CCardBody class="d-flex">
                              <CRow class="w-100">
                                <CCol>
                                  <CInput v-model="item.Name" :label="$t('System/Themes.Menu.Name')"/>
                                  <CInput v-model="item.SubTitle" :label="$t('System/Themes.Menu.SubTitle')"/>
                                  <CInput v-model="item.URL" :label="$t('System/Themes.Menu.URL')"/>
                                  <CSelect :value.sync="item.Target" :label="$t('System/Themes.Menu.Target')" :options="[{ label: $t('System/Themes.Menu.Target/Self'), value: '_self' }, { label: $t('System/Themes.Menu.Target/Blank'), value: '_blank' }]"/>
                                  <div class="w-100 d-flex flex-column my-2 mb-4">
                                    <label for="MenuSwitch">啟用選單</label>
                                    <CSwitch
                                      color="success"
                                      shape="pill"
                                      :checked.sync="item.Open"
                                      id="MenuSwitch"
                                    />
                                  </div>
                                  <CButton block color="info" @click="AddSubMenuItem(index, locale)">{{ $t('Global.Add') }}{{ $t('System/Themes.Menu.SubMenu') }}</CButton>
                                </CCol>
                                <CCol>
                                  <h5>{{ $t('System/Themes.Menu.SubMenu') }}</h5>
                                  <Draggable
                                    v-model="item.SubMenu"
                                    v-bind="DragOptions"
                                    @start="drag = true"
                                    @end="drag = false"
                                  >
                                    <CCard v-for="(subItem, subIndex) in item.SubMenu" :key="subIndex">
                                      <CCardHeader class="d-flex align-items-center">
                                        <div class="flex-grow-1" style="cursor: pointer;" @click="(SubMenuAccordion = SubMenuAccordion === `${index}-${subIndex}` ? false : `${index}-${subIndex}`)">
                                          {{ subItem.Name }}
                                        </div>
                                        <CButton color="danger" size="sm" @click="DeleteLocaleMenuItem(`${index}-${subIndex}`, locale)">{{ $t('Global.Delete') }}</CButton>
                                      </CCardHeader>
                                      <CCollapse :show="SubMenuAccordion === `${index}-${subIndex}`">
                                        <CCardBody class="d-flex flex-column">
                                          <CInput v-model="subItem.Name" :label="$t('System/Themes.Menu.Name')"/>
                                          <CInput v-model="subItem.URL" :label="$t('System/Themes.Menu.URL')"/>
                                          <CSelect :value.sync="subItem.Target" :label="$t('System/Themes.Menu.Target')" :options="[{ label: $t('System/Themes.Menu.Target/Self'), value: '_self' }, { label: $t('System/Themes.Menu.Target/Blank'), value: '_blank' }]"/>
                                          <label for="MenuSwitch">啟用選單</label>
                                          <CSwitch
                                            color="success"
                                            shape="pill"
                                            :checked.sync="subItem.Open"
                                            id="MenuSwitch"
                                          />
                                        </CCardBody>
                                      </CCollapse>
                                    </CCard>
                                  </Draggable>
                                </CCol>
                              </CRow>
                            </CCardBody>
                          </CCollapse>
                        </CCard>
                      </Draggable>
                    </CCardBody>
                    <CCardFooter class="d-flex justify-content-between">
                      <CButton color="info" class="px-4 mt-3 mr-1" @click="AddMenuItem(locale)">{{ $t('Global.Add') }}</CButton>
                      <CButton color="success" class="px-4 mt-3" @click="SetData('SubscriptionPlatform')">
                        {{ $t('Global.Update') }}
                      </CButton>
                    </CCardFooter>
                  </CCollapse>
                </CCard>
              </template>
            </CTab>
            <CTab :title="$t('System/Themes.Style')">
              <h5 class="d-flex justify-content-between">
                {{ $t('System/Themes.CSS') }}
              </h5>
              <hr>
              <prism-editor class="my-editor" v-model="BasicData.SubscriptionPlatform.Style.CSS" :tabSize="2" :highlight="highlighter" line-numbers />
              <div class="d-flex justify-content-between">
                <CButton color="success" class="px-4 mt-3" @click="SetData('SubscriptionPlatform')">{{ $t('Global.Update') }}</CButton>
              </div>
            </CTab>
          </CTabs>
          <CElementCover v-if="(Loading === true)" :opacity="0.75">
            <CSpinner color="success"/>
          </CElementCover>
        </CCardBody>
      </CCard>
    </CContainer>
    <MediaStore :Config="MediaStoreConfig" @OK="SetImages(ChooseImageType, ChooseImageIndex, ChooseImageItem)" />
  </main>
</template>

<route>
{
  "meta": {
    "label": "訂閱平台設定"
  }
}
</route>

<script>
// import Prism Editor
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css' // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-css'
import 'prismjs/themes/prism-tomorrow.css' // import syntax highlighting styles

export default {
  name: 'SubscriptionPlatform',
  layout: 'manage',
  components: {
    PrismEditor,
    WidgetsLocales: () => import('@/components/widgets/WidgetsLocales'),
    Draggable: () => import('vuedraggable'),
    MediaStore: () => import('@/components/public/Media'),
  },
  data () {
    return {
      Accordion: 0,
      BasicData: {
        SubscriptionPlatform: {
          Index: [],
          PopUp: {},
          Menu: [],
          Style: {
            CSS: ''
          }
        }
      },
      Loading: false,
      Submit: false,
      NowChooseLanguage: 'default',
      ChooseImageType: '',
      ChooseImageIndex: false,
      ChooseImageItem: '',
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false
      },
      MenuAccordion: '',
      Features: [],
      CategoryList: [],
      FieldName: '',
      CarouselAccordion: '',
      PopAccordion: '',
      SubMenuAccordion: '',
      drag: false,
      noItemsView: {
        noResults: this.$t('Global.NoResults'),
        noItems: this.$t('Global.NoItems')
      }
    }
  },
  computed: {
    DragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    ModeOptions() {
      return Object.keys(this.$t('Channel/Subscription.ModeOption')).map(value => {
        return {
          label: this.$t('Channel/Subscription.ModeOption.' + value),
          value
        }
      })
    },
  },
  mounted() {
    this.Loading = true
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
      this.Loading = false
    }).catch((err) => {
      this.$Progress.fail()
      this.Loading = false
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
      this.Features = PermissionSession.Features.System.Basic
      const LoadData = [
        this.GetCategories(),
        this.GetData('SubscriptionPlatform')
      ]
      return Promise.all(LoadData).then(() => {
        if (this.$store.state.user.permission.MutipleLocale) {
          this.$store.state.user.permission.Locales.forEach((locale) => {
            if (!this.BasicData.SubscriptionPlatform['PopUp_' + locale]) {
              this.$set(this.BasicData.SubscriptionPlatform, 'PopUp_' + locale, {})
            }
            if (!this.BasicData.SubscriptionPlatform['Menu_' + locale]) {
              this.$set(this.BasicData.SubscriptionPlatform, 'Menu_' + locale, [])
            }
            if (!this.BasicData.SubscriptionPlatform['Index_' + locale]) {
              this.$set(this.BasicData.SubscriptionPlatform, 'Index_' + locale, [])
            }
          })
        }
        this.NowChooseLanguage = this.$store.state.user.permission.DefaultLocale || 'default'
      }).catch((err) => {
        throw err
      })
    },
    GetData(type) {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/get/' + type,
        method: 'post'
      }).then(({data}) => {
        this.$set(this.BasicData, type, {
          ...this.BasicData[type],
          ...data
        })
        return true
      }).catch((err) => {
        throw err
      })
    },
    SetData(type) {
      const data = {
        [type]: this.BasicData[type]
      }
      if (data.Info && data.Info.Domain) {
        data.Info.Domain = data.Info.Domain.replace('https://', '').replace('http://', '').replace('/', '')
      }
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/set/' + type,
        method: 'post',
        data
      }).then(() => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/ModifySuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.System/ModifyFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    RemoveData(type, index) {
      this.BasicData[type].splice(index, 1)
    },
    //圖片處理
    OpenMediaStore(type = 'Carousel', item = 'Cover') {
      this.ChooseImageType = type
      this.ChooseImageItem = item
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = false
    },
    SetImages (type = 'PopUp', index, item = '') {
      this.SetSingleImage(type, item)
    },
    SetSingleImage(type, item) {
      if (this.$store.state.user.permission.StaticDomain) {
        this.$set(this.BasicData.SubscriptionPlatform[type], item, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
      } else {
        this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
          this.$set(this.BasicData.SubscriptionPlatform[type], item, URL)
        })
      }
    },
    GetCategories () {
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/content/category/availableList',
          Data: {
            Taxonomy: 'Video'
          }
        }
      }).then(({ data }) => {
        this.CategoryList = data.Data.filter(item => item.Slug !== this.$route.params.slug).map((category) => {
          return { label: category.Name, value: category.Slug }
        })
      }).catch((err) => {
        throw err
      })
    },
    AddIndexSection() {
      if (this.NowChooseLanguage === 'default') {
        this.BasicData.SubscriptionPlatform.Index.push({
          Name: `分類${this.BasicData.SubscriptionPlatform.Index.length + 1}`,
          Category: '',
          Mode: 'Default'
        })
      } else {
        this.BasicData.SubscriptionPlatform['Index_' + this.NowChooseLanguage].push({
          Name: `分類${this.BasicData.SubscriptionPlatform['Index_' + this.NowChooseLanguage].length + 1}`,
          Category: '',
          Mode: 'Default'
        })
      }
    },
    DeleteIndexSection(key) {
      if (this.NowChooseLanguage === 'default') {
        this.BasicData.SubscriptionPlatform.Index.splice(key, 1)
      } else {
        this.BasicData.SubscriptionPlatform['Index_' + this.NowChooseLanguage].splice(key, 1)
      }
    },
    highlighter(code) {
      return highlight(code, languages.css, 'css') // languages.<insert language> to return html with markup
    },
    AddMenuItem(locale = 'default') {
      if (locale !== 'default') {
        this.BasicData.SubscriptionPlatform['Menu_' + locale].push({
          Name: `Menu#${this.BasicData.SubscriptionPlatform['Menu_' + locale].length + 1}`,
          URL: '',
          Target: '_self',
          Open: true,
          SubMenu: []
        })
        return true
      }
      this.BasicData.SubscriptionPlatform.Menu.push({
        Name: `Menu#${this.BasicData.SubscriptionPlatform.Menu.length + 1}`,
        URL: '',
        Target: '_self',
        Open: true,
        SubMenu: []
      })
    },
    AddSubMenuItem(key, locale = 'default') {
      if (locale !== 'default') {
        if (!this.BasicData.SubscriptionPlatform['Menu_' + locale][key].SubMenu) {
          this.$set(this.BasicData.SubscriptionPlatform['Menu_' + locale][key], 'SubMenu', [])
        }
        this.BasicData.SubscriptionPlatform['Menu_' + locale][key].SubMenu.push({
          Name: `SubMenu#${this.BasicData.SubscriptionPlatform['Menu_' + locale][key].SubMenu.length + 1}`,
          URL: '',
          Target: '_self',
          Open: true,
          SubMenu: []
        })
        return true
      }
      if (!this.BasicData.SubscriptionPlatform.Menu[key].SubMenu) {
        this.$set(this.BasicData.SubscriptionPlatform.Menu[key], 'SubMenu', [])
      }
      this.BasicData.SubscriptionPlatform.Menu[key].SubMenu.push({
        Name: `SubMenu#${this.BasicData.SubscriptionPlatform.Menu[key].SubMenu.length + 1}`,
        URL: '',
        Target: '_self',
        Open: true
      })
    },
    DeleteMenuItem(key) {
      const keys = key.split('-')
      if(keys.length === 2) {
        this.BasicData.SubscriptionPlatform.Menu[keys[0]].SubMenu.splice(keys[1], 1)
      } else {
        this.BasicData.SubscriptionPlatform.Menu.splice(keys[0], 1)
      }
    },
    DeleteLocaleMenuItem(key, locale = 'default') {
      if (locale !== 'default') {
        const keys = key.split('-')
        if(keys.length === 2) {
          this.BasicData.SubscriptionPlatform['Menu_' + locale][keys[0]].SubMenu.splice(keys[1], 1)
        } else {
          this.BasicData.SubscriptionPlatform['Menu_' + locale].splice(keys[0], 1)
        }
      }
    },
  }
}
</script>
